<template>
  <DescriptorCard
    class_name="Account"
    title="Accounts"
    v-bind="Object.assign({}, $attrs)"
    :_read_params="_read_params"
    :fixed_params="fixed_params"
    :_index_params="_index_params"
    :enable_edit_modal="false"
    @rowClicked="rowClicked"
    @addButtonClicked="addButtonClicked"
    @onModalOpen="onModalOpen"
    :init_params="init_params"
  >
    <template v-slot:modal="modal_props">
      <AccountModal
        ref="accountModalRef"
        :fixed_params="fixed_params"
        :_read_params="_read_params"
        :_params="modal_props._params"
        @close="modal_props.onModalClose"
      />
    </template>
  </DescriptorCard>
</template>

<script>
import _ from 'lodash';
// import TabView from '@/descriptor/coreui/components/modal/TabView';
import AccountDetail from './AccountDetail';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    AccountModal: () => import('./AccountModal'),
  },
  props: {
    _index_params: {},
    fixed_params: {},
    _read_params: {
      type: Object,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    init_params() {
      let init_params = {};

      let user_account_manager = _.first(this.$auth.user[this.$d.getRelationAliasByNames('AccountManager', 'WithUser')]);

      init_params[this.$d.getRelationAliasByNames('Account', 'CreatedByAccountManager')] = user_account_manager ? [user_account_manager] : null;

      return init_params;
    },
  },
  watch: {},
  created() {
    this._ = _;
  },
  methods: {
    onModalOpen() {
      setTimeout(() => {
        this.$refs['accountModalRef'].open();
      }, 0);
    },
    rowClicked(account) {
      console.log(account);
      let new_tab = {
        title: account['account_number'],
        id: account['id'],
        component: AccountDetail,
        props: {
          _account: account,
        },
      };
      this.$store.dispatch('openTab', new_tab);
    },
    addButtonClicked() {
      /* let new_tab = {
        title: 'NEW',
        component: AccountDetail,
        id: null,
        props: {
        }
      };
      this.$store.dispatch('openTab', new_tab); */
    },
  },
};
</script>

<style scoped></style>
