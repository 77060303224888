<template>
  <!-- <div class="animated fadeIn"> -->
  <div class="account_detail">
    <!-- <b-card :title="account['company_name']"> -->
    <b-card v-if="account_id" class="m-0">
      <span style="font-size:24px; font-weight: 400">{{ account['company_name'] }}</span>
      <!-- TODO: reimplement manual reload by clearing in each component -->
      <!-- <b-button @click="getDebounced()" variant="default" class="float-right">
        <i class="fa fa-sync-alt"></i>
      </b-button> -->
      <!-- <h6>{{ account['account_number'] }}</h6> -->
    </b-card>
    <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
    <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
    <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
    <b-card no-body class="tab_card">
      <!-- {{account}} -->
      <b-tabs card pills lazy>
        <!-- cannot be lazy because the response after updating will just be dropped if still loading before switching tabs -->
        <!-- <b-tabs card pills> -->
        <b-tab title="Main" active>
          <AccountMain :account.sync="account" />
        </b-tab>
        <b-tab title="Del. Addresses">
          <DeliveryAddresses :account.sync="account" />
        </b-tab>
        <!-- <b-tab title="Calls"><Calls :account="account" :user="this.$auth.user"/></b-tab>
        <b-tab title="Events"><Events :account="account" :user="this.$auth.user"/></b-tab> -->
        <b-tab title="Calls"><Calls :account="account"/></b-tab>
        <b-tab title="Events"><Events :account="account"/></b-tab>
        <b-tab title="Billing">
          <BillingAccountMain v-if="billing_account_" :billing_account.sync="billing_account_" @submitted="get()" />
          <div v-else-if="account">
            <div class="alert alert-warning">No Billing Account linked, edit <strong>existing</strong> Billing Account or add <strong>new</strong></div>
            <b-btn @click="$refs['modalRef'].open()">Add</b-btn>
            <Modal
              @close="get()"
              ref="modalRef"
              :class_name="'BillingAccount'"
              :fixed_params="$d.relationFixedParams('BillingAccount', 'HasAccounts', [account])"
              :_params="$d.relationFixedParams('BillingAccount', 'HasMainAccount', [account])"
            >
            </Modal>
            <!-- <DescriptorCard
              :fixed_params="$d.relationFixedParams('BillingAccount', 'HasAccounts', [account])"
              class_name="BillingAccount"
              title="Billing Accounts"
              @onModalClose="get()"
            ></DescriptorCard> -->
          </div>
          <div v-else>
            <div class="alert alert-warning">Create an Account before linking Billing Account</div>
          </div>
        </b-tab>
        <b-tab title="Quotation">
          <Quotation :billing_account="billing_account_" />
        </b-tab>
        <b-tab title="Pricing">
          <Pricing v-if="account" :account.sync="account" :billing_account.sync="billing_account_" :addresses.sync="addresses_" />
          <!-- <Pricing v-if="account" :account.sync="account" :billing_account.sync="billing_account_" :addresses.sync="addresses_" @submitted="get()" /> -->
        </b-tab>
        <b-tab title="Ordering">
          <Ordering v-if="account && billing_account_" :account="account" :billing_account="billing_account_" />
          <div v-else class="alert alert-warning">Billing Account Required</div>
        </b-tab>
        <b-tab title="Renting"> <Renting :billing_account="billing_account_"/></b-tab>
        <b-tab title="Delivery"> <Delivery :account="account" :billing_account="billing_account_"/></b-tab>
        <b-tab title="Returns"> <Returns :account="account" :billing_account="billing_account_"/></b-tab>
        <b-tab title="Servicing"> <Servicing :billing_account="billing_account_"/></b-tab>
        <b-tab title="Finance By Bil. Acc."> <FinanceByBillingAccount :billing_account="billing_account_"/></b-tab>
        <b-tab title="Statistics"> <Statistics :billing_account="billing_account_"/></b-tab>
      </b-tabs> 
    </b-card>
  </div>
</template>

<script>
import { QLinearProgress } from 'quasar';
export default {
  components: {
    QLinearProgress,
    TabView: () => import('@/descriptor/coreui/components/modal/TabView'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    Modal: () => import('@/descriptor/coreui/Modal'),
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    AccountMain: () => import('./tabs/AccountMain'),
    Calls: () => import('./tabs/Calls'),
    Events: () => import('./tabs/Events'),
    BillingAccountMain: () => import('./tabs/BillingAccountMain'),
    DeliveryAddresses: () => import('./tabs/DeliveryAddresses'),
    Pricing: () => import('./tabs/Pricing'),
    Quotation: () => import('./tabs/Quotation'),
    Ordering: () => import('./tabs/Ordering'),
    Renting: () => import('./tabs/Renting'),
    Delivery: () => import('./tabs/Delivery'),
    Returns: () => import('./tabs/Returns'),
    Servicing: () => import('./tabs/Servicing'),
    FinanceByBillingAccount: () => import('./tabs/FinanceByBillingAccount'),
    Statistics: () => import('./tabs/Statistics'),
  },
  props: {
    _account: {
      type: Object,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      account_: null,
      // account_: {}, //if new acount
    };
  },
  computed: {
    account_id() {
      return _.get(this.account, ['id'], null);
    },
    account: {
      get: function() {
        return this.account_ || this._account;
      },
      set: function(newValue) {
        this.account_ = newValue;
      },
    },
    /* billing_account() {
      return _.get(this.account[this.$d.getRelationAliasByNames('BillingAccount', 'HasAccounts')], [0]);
    }, */
    billing_account_: {
      get: function() {
        if (this.account && this.account[this.$d.getRelationAliasByNames('BillingAccount', 'HasAccounts')]) {
          return this.account[this.$d.getRelationAliasByNames('BillingAccount', 'HasAccounts')][0];
        }
        // if (this.account) {
        //   return _.get(this.account[this.$d.getRelationAliasByNames('BillingAccount', 'HasAccounts')], [0], null); //NOTE: this method of getting data might be the main reason billing does not update
        // }
        return null;
      },
      set: function(newValue) {
        console.log('sett billing_account_', newValue);
        let account = Object.assign({}, this.account);
        _.set(account[this.$d.getRelationAliasByNames('BillingAccount', 'HasAccounts')], [0], newValue);
        this.account = account;
      },
    },
    addresses_: {
      get: function() {
        return this.account[this.$d.getRelationAliasByNames('Account', 'HasAddresses')];
      },
      set: function(newValue) {
        console.log('sett addresses_', newValue);
        let account = Object.assign({}, this.account);
        _.set(account[this.$d.getRelationAliasByNames('Account', 'HasAddresses')], [0], newValue);
        this.$emit('update:account', account);
      },
    },
    read_params() {
      return {
        index_withs: false,
        with_virtual_properties: true,
        // with_virtual_properties: ['current_account_manager'],
        with_relationships: [
          {
            from_class_name: 'Account',
            relationship_name: 'CreatedByAccountManager', //for Main Page
          },
          {
            from_class_name: 'Account',
            relationship_name: 'CurrentlyManagedBy', //for Main Page
          },
          {
            from_class_name: 'Account',
            relationship_name: 'HasMainDeliveryContactPersons', //for OrderGenerator default Contact Person
          },
          {
            from_class_name: 'BillingAccount',
            relationship_name: 'HasAccounts',
            with_relationships: [
              {
                from_class_name: 'BillingAccount',
                relationship_name: 'HasMainInvoiceContactPersons', //for OrderGenerator default Contact Person
              },
            ],
          },
          {
            from_class_name: 'Account',
            relationship_name: 'HasAddresses', //for Pricing tab (addresses)
            with_relationships: [
              {
                from_class_name: 'Address',
                relationship_name: 'HasContactPersons', //for OrderGenerator default Contact Person
              },
            ],
          },
        ],
      };
    },
  },
  created() {
    this.getDebounced = _.debounce(this.get, 500);
    this.init();
  },
  methods: {
    init() {
      this.get();
    },
    get() {
      if (this.account_id) {
        this.loading = true;
        this.$d.request(
          'read',
          'Account',
          Object.assign(this.read_params, {
            id: this.account_id,
          }),
          account => {
            // this.$d.setCache(stateName, account);
            this.account_ = account;
            this.$set(this, 'account_', account); //reactivity issue

            this.error_message = null;
          },
          error_message => {
            this.error_message = error_message;
          },
          () => {
            this.loading = false;
            // setTimeout(() => {
            //   this.$forceUpdate(); //NOTE: temporary work-around to force production to update
            // }, 100);
          }
        );
      } else {
        console.error('no account_id, cannot read');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.account_detail  {
  // Styles
  .tab_card {
    background: none;
    > .tabs {
      > .tab-content {
        background: none;
        @include media-breakpoint-down(sm) {
          > .tab-pane {
            padding-left: 0; //remove modal body padding
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
